/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
//@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
/*@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';*/

/* Optional CSS utils that can be commented out */
/*@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";*/

.bluj-toast {
    --border-radius: 10px;
    --min-height: 80px;
    --pading: 10px;
    --box-shadow: 0 4px 2px -2px gray;
    /*border: {
        top: 5px solid #ff63c7;
    };*/
}
app-dashboard {
    border: {
        // top: 5px solid #ff63c7;
    }
}
.breadcrums {
    border: {
        // bottom: 3px solid #9ab7c0;
    }
}